import readyHandler from "../lib/readyHandler";

readyHandler.ready(() => {
  [...document.querySelectorAll(".card")].forEach((card: HTMLElement) => {
    card.addEventListener("click", () => {
      card.classList.toggle("flipped");
    });
  });

  [...document.querySelectorAll(".card a")].forEach(
    (link: HTMLAnchorElement) => {
      link.addEventListener("click", (e: Event) => {
        e.stopPropagation();
      });
    }
  );
});
