// Entry point for the build script in your package.json

// Responsive embeds
import responsiveEmbeds from "./lib/responsiveEmbeds";
responsiveEmbeds();

import "./features/card";
import "./features/detectPreserve3d";

//import "./vendor/jquery.lighter";

import jQuery from "jquery";
declare global {
  interface Window {
    jQuery: JQueryStatic;
  }
}
window.jQuery = jQuery;
import("jquery.lighter");
