import readyHandler from "../lib/readyHandler";

readyHandler.ready(() => {
  (function getPerspective() {
    const element = document.createElement("p"),
      html = document.getElementsByTagName("HTML")[0],
      body = document.getElementsByTagName("BODY")[0],
      properties = {
        webkitTransformStyle: "-webkit-transform-style",
        MozTransformStyle: "-moz-transform-style",
        msTransformStyle: "-ms-transform-style",
        transformStyle: "transform-style"
      };

    body.insertBefore(element, null);

    for (const i in properties) {
      if (element.style[i] !== undefined) {
        element.style[i] = "preserve-3d";
      }
    }

    const st = window.getComputedStyle(element, null),
      transform =
        st.getPropertyValue("-webkit-transform-style") ||
        st.getPropertyValue("-moz-transform-style") ||
        st.getPropertyValue("-ms-transform-style") ||
        st.getPropertyValue("transform-style");

    if (transform !== "preserve-3d") {
      html.className += " no-preserve-3d";
    } else {
      html.className += " preserve-3d";
    }
    document.body.removeChild(element);
  })();
});
